<template>
  <div>
    <div v-if="sent" v-html="$t('system.feedback.submit.success')" />

    <template v-else>
      <div class="e-feedback__entrytext" v-html="entryText" />

      <!-- Errormessages -->
      <el-alert
        v-for="(item, index) in errors.messages"
        :key="index"
        :title="$t(index)"
        type="error"
        v-html="$t(item)"
      />
      <!-- /Errormessages -->

      <el-form
        ref="feedbackForm"
        :model="formData"
        :rules="rules"
        class="e-feedback"
        label-position="top"
      >
        <el-form-item
          label="Bezieht sich deine Meinung auf die E-Mail- oder die Chat-Beratung? Bitte wähle aus:"
          prop="contact"
          :required="true"
        >
          <el-radio v-model="formData.contact" label="email"
            >E-Mail-Beratung</el-radio
          >

          <el-radio v-model="formData.contact" label="chat"
            >Chat-Beratung</el-radio
          >
        </el-form-item>

        <el-form-item
          v-for="item in formFields"
          :key="item.uuid"
          :prop="item.uuid"
          :label="item.question"
          :required="item.mandatory"
        >
          <el-input
            v-if="item.freetext"
            v-model="formData[item.uuid]"
            autosize
            type="textarea"
          />

          <FeedbackItem
            v-else
            :mandatory="item.mandatory"
            :item-key="item.uuid"
            @select="storeFeedback"
          />
        </el-form-item>

        <div class="e-form__row e-form__row--submit">
          <div />
          <el-button
            :loading="sending"
            type="primary"
            @click.prevent="submitForm('feedbackForm')"
            >{{ $t('system.feedback.send') }}</el-button
          >
        </div>
      </el-form>
    </template>
  </div>
</template>

<script>
import api from 'api'
import FeedbackItem from 'molecules/FeedbackItem/FeedbackItem'

export default {
  components: {
    FeedbackItem
  },

  props: {
    formFields: {
      type: Array,
      default: () => []
    },
    entryText: {
      type: String,
      default: ''
    },
    sent: {
      type: Boolean
    }
  },

  data: () => ({
    sending: false,
    errors: {},
    formData: {
      contact: null
    },
    rules: {}
  }),

  updated() {
    this.generateRules()
  },

  methods: {
    storeFeedback(data) {
      this.formData[data.key] = data.choice
    },

    generateRules() {
      this.rules['contact'] = [
        {
          required: true,
          message: this.$t('system.feedback.pleaseAnswerThisQuestion')
        }
      ]
      for (const item of this.formFields) {
        if (item.mandatory) {
          this.rules[item.uuid] = [
            {
              required: true,
              message: this.$t('system.feedback.pleaseAnswerThisQuestion')
            }
          ]
        }
      }
    },

    submitForm(formName) {
      this.errors.messages = []

      this.$refs[formName].validate(valid => {
        if (valid) {
          this.sendForm()
        } else {
          this.errors.status = 400
          this.errors.messages = ['system.feedback.pleaseAnswerAllQuestions']
          this.$forceUpdate()
        }
      })
    },

    async sendForm() {
      this.sending = true
      this.$emit('update:sent', false)

      try {
        const data = Object.assign({}, this.formData)
        await api.call('sendFeedback', null, data)

        this.$emit('update:sent', true)
      } catch (error) {
        this.$error(error)
        if (error.messages) {
          this.errors = error
        }
      } finally {
        this.sending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_assets/feedback';
</style>
